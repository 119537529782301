
.StripeElement.StripeElement--empty,
.StripeElement.StripeElement--focus,
.StripeElement.StripeElement--complete{
  display: block;
  width: 100%;
  padding: 0.55rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--falcon-input-color);
  background-color: var(--falcon-input-bg);
  background-clip: padding-box;
  border: 1px solid var(--falcon-input-border-color);
  appearance: none;
  border-radius: 5px;
}